<template>
  <v-app-bar app color="#af0606" dark fixed dense>
    <v-avatar tile class="mr-2">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/2/22/TIM_logo_2016.svg"
        alt="logo"
      />
    </v-avatar>
    <v-toolbar-title>
      <span class="text-uppercase font-weight-light">Doctor</span
      ><span class="text-uppercase font-weight-bold">TIM</span>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PublicTitleBar',
  created() {
    this.$vuetify.theme.themes.light.primary = '#e60000'
    this.$vuetify.theme.dark = false
  },
}
</script>
